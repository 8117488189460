<template>
  <div class="tile is-parent is-half">
    <article class="tile is-child box detail-page-tile">
      <div class="columns is-gapless">
        <div class="column">
          <p class="title">Auto Items</p>
          <p class="subtitle">Add automatic miscellaneous items</p>
        </div>
        <div class="column">
          <div class="field is-pulled-right">
            <p class="control">
              <button class="button is-primary tooltip is-tooltip-topright"
                @click="toggleList()"
                data-tooltip="Add new auto item">
                <span class="icon">
                  <i class="mdi"
                    :class="{ 'mdi-plus' : !showMiscItemList, 'mdi-minus' : showMiscItemList }" />
                </span>
                <span>{{ buttonText }}</span>
              </button>
            </p>
          </div>
        </div>
      </div>
      <available-miscs v-show="showMiscItemList"
        v-model="entity.insurerAutoItems"
        :item-type="'MISC'"
        :insurer-id="entity.insurerId"
        :page-size="8" />
      <div v-if="showMiscItemList"
        class="is-divider" />
      <table class="table is-bordered is-striped is-narrow is-fullwidth">
        <thead>
          <tr>
            <th>Item No</th>
            <th>Description</th>
            <th>
              <div class="has-text-right">Value</div>
            </th>
            <th>
              <div class="has-text-centered">Rpt</div>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(autoItem, index) in activeItems"
            :key="autoItem.autoItemId"
            v-show="!autoItem.isDeleted">
            <td class="has-vertical-middle">{{ autoItem.itemNo }}</td>
            <td>
              <div class="control">
                <input class="input"
                  placeholder=""
                  v-model="autoItem.itemDesc"
                  v-focus-inserted>
              </div>
            </td>
            <td class="has-text-right">
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model="autoItem.price"
                  :min="0"
                  :precision="2" />
              </div>
            </td>
            <td class="has-vertical-middle has-text-centered">
              <input class="is-checkradio is-circle is-primary"
                :class="{ 'has-background-color': autoItem.reportOnly }"
                :id="`report${index}`"
                :name="`report${index}`"
                type="checkbox"
                v-model="autoItem.reportOnly">
              <label :for="`report${index}`"
                data-label-empty />
            </td>
            <td class="has-vertical-middle has-text-centered">
              <a class="button is-danger is-small is-inverted"
                @click="deleteItem(autoItem, index)">
                <span class="icon is-medium">
                  <i class="mdi mdi-delete mdi-24px" />
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </article>
  </div>
</template>

<script>
import VueNumeric from '@/components/VueNumeric'
import AvailableMiscs from './components/AvailableMiscs'
// import { BulmaCheckbox } from '@/components/BulmaCheckbox'
import { FocusInserted } from '@/components/directives'

export default {
  name: 'InsurerAutoItems',
  components: {
    VueNumeric,
    AvailableMiscs
    // BulmaCheckbox
  },
  directives: {
    FocusInserted
  },
  props: {
    entity: null
  },
  data() {
    return {
      showMiscItemList: false,
      buttonText: 'Add',
      searchText: '',
      isSearching: false
    }
  },
  computed: {
    activeItems() {
      return this.entity.insurerAutoItems
    }
  },
  methods: {
    deleteItem(item, index) {
      if (item.isNew) {
        const itemIndex = this.entity.insurerAutoItems
          .map(function(obj) {
            return obj.autoItemId
          })
          .indexOf(item.autoItemId)
        if (itemIndex >= 0) {
          this.entity.insurerAutoItems.splice(itemIndex, 1)
        }
      } else {
        item.isDeleted = true
      }
    },
    toggleList() {
      this.showMiscItemList = !this.showMiscItemList
      this.buttonText = this.showMiscItemList ? 'Close' : 'Add'
    }
  }
}
</script>

<style lang="scss">
</style>
