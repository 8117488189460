import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Name', 'name', true, 'asc', 'left'),
  new TableColumn('Suburb', 'suburb', true, 'asc', 'left'),
  new TableColumn('Times', 'labourType', false, 'asc', 'left'),
  new TableColumn('Ecom', 'ecomTypeName', true, 'asc', 'centered'),
  new TableColumn('Method', 'quotingMethod', false, 'asc', 'centered'),
  new TableColumn('Active', 'active', true, 'asc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const MiscItemColumns = [
  new TableColumn('ItemNo', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Desciption', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Value', 'value', false, 'asc', 'right'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { Columns, MiscItemColumns }